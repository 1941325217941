.mainContainerDiv {
  background-color: #fff;
}

.sideBarContainer {
  background-color: #fff;
  float:left;
  height: 100%;
  
}

.appContainer {
  height: 100vh;
  overflow:auto
}

.appHero {
  height: fit-content;
  background-color: #27a9e1;
  padding: 10px;
}

.appHeroTextLine{
  color: #fff;
  font-size: clamp(24px, 2vw, 32px);
  
}

.appContent {
  padding-top: 0;
  padding-left: 15px;
 }

.sideBarTitle {
  padding: 10px 10px;
}
.sideBarTitleBlock {
  color: #666;
  padding: 5px;
  height: 20px;
  border-radius: 4px;
  font-weight: bold;
}
.sideBarCollapse {
  display: flex;
  justify-content: space-between; /* Objects spaced evenly */
  align-items: center; 
}

.pressableIcon { 
  cursor: pointer;
}

.invisIcon {
  color: white;
}
.rotatedIcon {
  transform: rotate(180deg); /* Rotate the icon by 180 degrees */
  cursor: pointer;
}

.activeIcon {
  color: white;
}

.activeIconInBar {
  color: white;
}

.sideBarMenuItem {
  color: #666;
  padding: 5px;
  height: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between; /* Objects spaced evenly */
  align-items: center; 
 
}

.sideBarMenuItemActive {
  color: white;
  
  padding: 5px;
  height: 20px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: space-between; /* Objects spaced evenly */
  align-items: center; 
}

.sideBarHero {
  display: flex;
  justify-content: center;
  background-color: #fff;
}

.sideBarStyle {
  float: left;
  background-color: #f3f3f3;
  height: 100vh;
}

.infoSection {
  margin-top: 20px;
  padding-bottom: 40px;
  padding-right: 20px;
  
}
.infoBlock {
 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 5px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
  word-wrap: break-word; /* Add word wrapping */
}
.formSection {
  padding-top: 10px;
  padding-bottom: 40px;
  padding-right: 10px;
}

.inputForm {
    background-color: #fff;
    color: #000;
    border: 2px solid #666;
    padding: 10px 10px;
    border-radius: 4px;
    font-size: 14px;
  }

  .inputFormEmpty {
    background-color: #fff;
    color: #000;
    border: 2px solid #df4543;
    padding: 10px 10px;
    border-radius: 4px;
    font-size: 14px;
  }

.inputTextForm {
    width: 100%;
    max-width: 550px;
    height: 150px;
    background-color: #fff;
    color: #000;
    border: 2px solid #666;
    padding: 10px 10px;
    padding-right: 10px;
    border-radius: 4px;
    box-sizing: border-box;
    resize: none;
    overflow-y:auto;
    font-size: 14px;
}

.displayInputTextForm {
  width: 100%;
  max-width: 550px;
  height: 150px;
  background-color: #fff;
  color: #000;
  border: 2px solid #666;
  padding: 10px 10px;
  padding-right: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  resize: none;
  overflow-y:auto;
  font-size: 14px;
}

.submitBtn {
  color: white;
  background-color: #27a9e1;
  padding: 5px 5px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
}
.resetBtn {
  color: white;
  background-color: #df4543;
  padding: 5px 5px;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
}

.submitBtnGreyed {
  color: #fff;
  background-color: #ddd;
  padding: 5px 5px;
  border-radius: 4px;
  border: 1px solid #fff;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
}

.nieAFR {
  color: #df4543;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.dataBoxContainer {

  background-color: #f2f2f2;
}

.dataBoxDivider {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr)); /* Responsive grid columns */
  gap: 20px; /* Set your desired gap between grid items */

}
.dataBoxDividerExtra {
  padding: 20px;
  

}

.dataBox {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  width: 250px;
  height: 130px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
}

.ekstraInfoBox {
  position: relative;
  border-radius: 10px;
  background-color: #fff;
  width:230px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding: 20px;
  display: grid;

}

.dataBoxMainHeadding {
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bold;
  font-size: 20px;
}

.dataBoxHeader {
font-weight: bold;
display: flex;
  justify-content: space-between; /* Objects spaced evenly */
  align-items: center; 
} 

.disclaimerBtnContainer {
  font-weight: bold;
  display: flex;
   
    align-items: center; 
  } 

.dataBoxSideBySideHeader{
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.dataSideBySideItem{
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 40px;
  
  
}

.dataDisplayNum {
  font-weight: bold;
  font-size: 28pt;
}

.dataDisplayNumSmall {
  font-weight: bold;
  font-size: 22pt;
}




.dataDisplayNumMed {
  font-weight: bold;
  font-size: 20pt;
}
.dataFeedBack {
  color: #666;
}



.meerBtnPos {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.meerBtn {
  border: 2px solid #666; 
  color: #666;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
}

.appFooter {
  height: 50px;
}



.loader-overlay {
  z-index:10;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(201, 201, 201, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  position: relative;
  background: #fff;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
}
/* Media query for adjusting styles on smaller screens */
@media (max-width: 600px) {
  .inputTextForm {
    width: 100%; /* Adjust width for smaller screens */
    max-width: none; /* Remove maximum width on smaller screens */
  }
}