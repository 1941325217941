/* Modal.css */
.modal-overlay {
    z-index:10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-container {
    position: relative;
    background: #fff;
    width: 80%;
    padding: 20px;
    border-radius: 8px;
  }

  .authModal-container {
    position: relative;
    background: #fff;
    width: 60%;
    padding: 20px;
    border-radius: 8px;
  }

  

  .shopIcon {
    display: flex;
    position: relative;
    padding-top: 50px;
    padding-bottom: 20px;
    /*justify-content: space-between; /* Objects spaced evenly */
    align-items: center; 
    justify-content: center;
  }

  .VivALogo {
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
     
      color: #666;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 20px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      width: fit-content;
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }

  .close-buttonAuth {
    position: absolute;
    top: 10px;
    right: 10px;
     
      color: #666;
      background-color: #fff;
      padding: 5px 10px;
      border-radius: 20px;
      font-weight: bold;
      display: flex;
      
     
    cursor: pointer;
    font-size: 20px;
    color: #333;
  }

  .vivaLogo {
    position: absolute;
    top: 10px;
    
     
      
      padding: 5px 10px;
     
     
      display: flex;
      justify-content: center;
      width: fit-content;
    
    
    
  }